.card {
  background: var(--color-gray-1);
  border-radius: 4px;

  .photoWrapper {
    min-height: 180px;
    background-color: var(--color-green-2);

    .icon {
      min-height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;

      > svg {
        font-size: 100px;
      }
    }

    .image {
      height: 180px;
      background-position: center;
      background-size: cover;
    }
  }

  .contentWrapper {
    padding: 16px;

    .cardFeatures {
      margin-top: 8px;
      font-size: 14px;
      opacity: .45;
    }

    .cardBody {
      font-size: 16px;
    }
  }

  .actionWrapper {
    border-top: 1px solid var(--color-border);
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;

    &.completed {
      padding: 8px 60px;

      @media(max-width: 600px) {
        padding: 8px 12px;
      }

      .btnEdit {
        width: auto;
      }
    }

    &.expired {
      justify-content: center;
    }

    .btnEdit {
      opacity: .45;
      box-shadow: none;
      border: none;
      width: 100%;

      &:hover {
        opacity: 1;
      }
    }
  }
}

@font-family: 'Manrope', sans-serif;@primary-color: #1F803F;@border-radius-base: 4px;@primary-1: #E9F2EC;