.wrapper {
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    display: flex;
    align-items: center;

    .iconWrapper {
      margin-right: 8px;
      font-size: 16px;
      height: 22px;
    }
  }

  .actionsWrapper {
    display: flex;
    align-items: center;

    .statusWrapper {
      margin-right: 8px;
      width: 120px;
    }

    .btnCreateReport {
      margin-right: 16px;
    }
  }
}

@font-family: 'Manrope', sans-serif;@primary-color: #1F803F;@border-radius-base: 4px;@primary-1: #E9F2EC;