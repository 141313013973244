.hidden {
  display: none !important;
}

.previewModalImage {
  margin-top: 25px;
}

.image {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@font-family: 'Manrope', sans-serif;@primary-color: #1F803F;@border-radius-base: 4px;@primary-1: #E9F2EC;