.headRow {
  padding: 0 0 16px 0;
  border-bottom: 1px solid #EDF0F3;
}

.templateRow {
  &Padding {
    padding: 16px 0;
  }

  &Border {
    border-bottom: 1px solid #EDF0F3;
  }
}

.gradingPoint {
  width: 8px;
  height: 8px;
  margin-right: 3px;
  border-radius: 4px;
}

@font-family: 'Manrope', sans-serif;@primary-color: #1F803F;@border-radius-base: 4px;@primary-1: #E9F2EC;