.form {
  label:before {
    display: none !important;
  }
}

.error {
  margin: -6px 0 28px;
}

.checkbox {
  line-height: 26px;

  a {
    font-size: 14px;
    line-height: 22px;
    color: var(--color-black);
    float: right;
  }
}

@font-family: 'Manrope', sans-serif;@primary-color: #1F803F;@border-radius-base: 4px;@primary-1: #E9F2EC;