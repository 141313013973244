.cardWrapper {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  margin-bottom: 24px;
}

.deleteBtn {
  border-color: var(--color-text-danger) !important;
  color: var(--color-text-danger) !important;
}

.selectedCheckbox {
  position: absolute;
  right: 12px;
  top: 12px;
}

@font-family: 'Manrope', sans-serif;@primary-color: #1F803F;@border-radius-base: 4px;@primary-1: #E9F2EC;