.sider {
  min-height: 350px;
  height: 100%;
  display: grid;
  grid-template-rows: 72px 1fr auto;
  border-right: 1px solid var(--color-gray-4);

  .logoutWrapper {
    background: var(--color-gray-2);
    padding-bottom: 24px;

    .logout {
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: transparent;
      border: none;
      width: 199px;
      padding: 9px 16px;
    }
  }
}

@font-family: 'Manrope', sans-serif;@primary-color: #1F803F;@border-radius-base: 4px;@primary-1: #E9F2EC;