.wrapper {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(176px, 1fr));
  margin-top: 24px;

  .imageWrapper {
    position: relative;
    height: 300px;
    display: flex;
    align-items: center;
    overflow: hidden;

    .date {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 500;
      font-size: 6px;
      padding: 1px 4px;
      text-transform: uppercase;
      background-color: rgba(0, 0, 0, .7);
      text-align: center;
      color: #fff;
      width: fit-content;
    }

    .img {
      height: 100%;
      width: 100%;
      background-position: center;
      background-size: cover;
    }

    .checkbox {
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }
}

.actions {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  grid-gap: 15px;
  justify-content: space-between;
}

@font-family: 'Manrope', sans-serif;@primary-color: #1F803F;@border-radius-base: 4px;@primary-1: #E9F2EC;