.textCenter {
  display: flex; 
  justify-content: center;
  flex-direction: column;
}

.text {
  max-width: 190px;
  font-size: 12px;
  line-height: 20px;
  color: var(--color-text-secondary);
}

@font-family: 'Manrope', sans-serif;@primary-color: #1F803F;@border-radius-base: 4px;@primary-1: #E9F2EC;