.galleryModal {

  .ant-card-body {
    min-height: 350px;
  }

  .ant-modal-close-x {
    margin-top: 5px;
  }

  .ant-modal-content {
    background-color: red;

    .ant-modal-body {
      padding: 0;
    }
  }

  .card {
    .card-head {
      padding: 10px 0;
    }

    .ant-upload-select-picture-card {
      border-radius: 4px;
      margin: 0;
      height: 300px !important;
      width: 100%;
    }

    .imagesList {
      display: grid;
      grid-template-columns: repeat(6, 175px);
      grid-gap: 15px;

      .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .imageBlock {
        border-radius: 4px;
        position: relative;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100%;
        cursor: pointer;
        height: 300px;
        background-color: #fafafa;
        overflow: hidden;

        &.checked {
          pointer-events: none;
          opacity: .5;
        }

        img {
          display: block;
          width: auto;
          height: 300px;
        }

        .checkbox {
          position: absolute;
          top: 12px;
          right: 12px;
        }

        .date {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          font-weight: 500;
          font-size: 6px;
          padding: 1px 4px;
          text-transform: uppercase;
          background-color: rgba(0, 0, 0, .7);
          text-align: center;
          color: #fff;
          width: fit-content;
        }
      }
    }
  }
}


@font-family: 'Manrope', sans-serif;@primary-color: #1F803F;@border-radius-base: 4px;@primary-1: #E9F2EC;