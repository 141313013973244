.svg {
  width: 504px;
  height: 135px;
  max-width: 80vw;
  transition: ease-in 1.8s;
  color: #fff;

  &.active {
    opacity: 0;
  }
}

@font-family: 'Manrope', sans-serif;@primary-color: #1F803F;@border-radius-base: 4px;@primary-1: #E9F2EC;