.success {
  text-align: center;
  max-width: 360px;
  width: 90vw;

  h1 {
    margin-bottom: 16px;
  }
}

.box {
  margin-top: 10px;
}

label:before {
  display: none !important;
}

@font-family: 'Manrope', sans-serif;@primary-color: #1F803F;@border-radius-base: 4px;@primary-1: #E9F2EC;