@import '~antd/dist/reset.css';
:root,
[data-theme="default"] {
  --color-green-1: #e9f2ec;
  --color-green-2: #c9e1d1;
  --color-green-3: #9fc8ac;
  --color-green-4: #72af86;
  --color-green-5: #479762;
  --color-green-6: #1f803f;
  --color-green-7: #E9F2EC;
  --color-green-8: #165b2d;
  --color-green-9: #124924;
  --color-green-10: #0e3a1c;
  --color-text-inverse: #FFFFFF;
  --color-text-title: rgba(0, 0, 0, 0.85);
  --color-text-primary: rgba(0, 0, 0, 0.85);
  --color-text-secondary: rgba(0, 0, 0, 0.45);
  --color-text-disabeled: rgba(0, 0, 0, 0.25);
  --color-text-placeholder: rgba(0, 0, 0, 0.25);
  --color-text-danger: #FF4D4F;
  --color-text-warning: #FAAD14;
  --color-text-success: #52C41A;
  --color-orange: #F15C22;
  --color-blue: #436AB3;
  --color-grading-0: #FF4D4F;
  --color-grading-1: #FA8214;
  --color-grading-2: #FAAD14;
  --color-grading-3: #52C41A;
  --color-grading-opacity-0: rgba(255, 77, 79, 0.1);
  --color-grading-opacity-1: rgba(250, 130, 20, 0.1);
  --color-grading-opacity-2: rgba(250, 173, 20, 0.1);
  --color-grading-opacity-3: rgba(82, 196, 26, 0.1);
  --color-tooltip: rgba(0, 0, 0, 0.75);
  --color-header-background: #F0F2F5;
  --color-page-background: #F0F2F5;
  --color-sidebar-background: #FFFFFF;
  --color-popover: #FFFFFF;
  --color-divider: #EDF0F3;
  --color-border: #EDF0F3;
  --color-icons: rgba(0, 0, 0, 0.75);
  --color-menuitem-background: #E9F2EC;
  --color-menuitem-text-selected: #1F803F;
  --color-table-header: #FAFAFA;
  --color-table-sort-header: #EFEFEF;
  --color-table-sort-cell: #FAFAFA;
  --color-item-background-hover: #FAFAFA;
  --color-gray-1: #FFFFFF;
  --color-gray-2: #FAFAFA;
  --color-gray-3: #F5F5F5;
  --color-gray-4: #F0F0F0;
  --color-gray-5: #D9D9D9;
  --color-gray-6: #BFBFBF;
  --color-gray-7: #8C8C8C;
  --color-gray-8: #595959;
  --color-gray-9: #434343;
  --color-gray-10: #262626;
  --color-gray-11: #1F1F1F;
  --color-gray-12: #141414;
  --color-gray-13: #000000;
}
body {
  min-height: 100vh;
}
body .ant-pro-table-search-query-filter .ant-pro-form-query-filter .ant-row-start .ant-form-item-label {
  min-width: 105px;
}
body .ant-pro-table-search-query-filter .ant-pro-form-query-filter .ant-row-start .cut-off-date-field-disabled {
  pointer-events: none;
}
body .ant-pro-table-search-query-filter .ant-pro-form-query-filter .ant-row-start .cut-off-date-field-disabled > div.ant-form-item-control > div > div > div {
  border-radius: 4px;
  background-color: #f5f5f5;
}
body .ant-pro-table-search-query-filter .ant-pro-form-query-filter .ant-row-start .pro-form-query-filter-actions .ant-form-item-label {
  min-width: 0;
}
body .ant-radio-button-wrapper-checked {
  border: 1px solid var(--color-green-6);
}
body .ant-alert-info {
  background-color: var(--color-green-7);
}
body .ant-page-header {
  background-color: var(--color-gray-1);
}
body .ant-layout-content {
  padding: 24px;
  overflow: auto;
}
body .ant-badge {
  min-width: max-content;
}
body .ant-table-column-title {
  font-weight: bold;
}
body .ant-table-column-sort {
  background: var(--color-table-sort-cell);
}
body .ant-pro-table-search-query-filter {
  border-radius: 4px;
}
body .ant-layout-header {
  height: 72px;
  background: var(--color-sidebar-background);
  padding: 0 0 16px 24px;
}
body .ant-menu-item-selected {
  background: var(--color-menuitem-background) !important;
}
body .ant-form-item-required:before {
  display: inline-block !important;
  margin-right: 4px;
  color: #f5222d;
  font-size: 1em;
  font-family: SimSun, sans-serif;
  line-height: 1em;
  content: '*';
}
body .ant-steps-horizontal {
  margin-bottom: 16px;
}
body .ant-collapse-borderless .ant-collapse-item .ant-collapse-header .ant-collapse-header-text {
  width: 100%;
}
body .textAlignCenter {
  text-align: center;
}
body .additionalInfo {
  text-align: center;
  font-size: 12px;
  line-height: 167%;
}
body .iconTd {
  font-size: 14px;
  padding: 0 15px;
}
body .colorGreen {
  color: var(--color-green-6);
}
body .status-in-progress {
  color: var(--color-text-warning);
  border-color: var(--color-text-warning);
}
body .status-expired {
  color: var(--color-text-title);
  border-color: var(--color-text-title);
}
body .status-completed {
  color: var(--color-green-6);
  border-color: var(--color-green-6);
}
body .status-not-started {
  color: var(--color-text-danger);
  border-color: var(--color-text-danger);
}
body .clip {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
body .truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: unset;
  overflow: hidden;
}
body .ant-menu-inline,
body .ant-menu-vertical,
body .ant-menu-vertical-left {
  border-right: none;
}
body .upload-report-pictures .ant-upload {
  width: 176px;
  height: 300px;
}
body .upload-report-pictures .ant-upload-select {
  width: 176px;
  height: 300px;
}
body .upload-report-pictures .ant-upload-select-picture-card {
  width: 176px;
  height: 300px;
}
body .upload-report-pictures .ant-upload-list-picture-card-container {
  width: 176px;
  height: 300px;
}
body .upload-report-pictures .ant-upload-list-picture-card-container .ant-upload-list-item-list-type-picture-card {
  padding: 0;
}
body .upload-report-pictures .check-box {
  border: 1px #d9d9d9 solid;
  border-radius: 4px;
  height: 100%;
}
body .upload-report-pictures .check-box .ant-checkbox-inner {
  width: 22px;
  height: 22px;
  border-radius: 8px;
}
body .upload-report-pictures .check-box .ant-checkbox-inner::after {
  left: 30.5%;
}
body .template_create ul {
  margin: 0;
  padding: 0;
}
body .template_form .ant-form-item-label {
  width: 386px;
  text-align: left;
}
body .template_form .ant-form-item-control {
  width: 355px;
}
body .template_form .ant-form-item {
  width: 100%;
}
body .template_form .radio-button-0 {
  border-color: var(--color-grading-0) !important;
  transition: all 0.3s;
}
body .template_form .radio-button-0:focus-within {
  box-shadow: var(--color-grading-opacity-0) 0 0 0 3px !important;
  transition: all 0.3s;
}
body .template_form .radio-button-0 .ant-badge-status-text {
  color: var(--color-grading-0);
  transition: all 0.3s;
}
body .template_form .radio-button-1 {
  border-color: var(--color-grading-1) !important;
  transition: all 0.3s;
}
body .template_form .radio-button-1:focus-within {
  box-shadow: var(--color-grading-opacity-1) 0 0 0 3px !important;
  transition: all 0.3s;
}
body .template_form .radio-button-1 .ant-badge-status-text {
  color: var(--color-grading-1);
  transition: all 0.3s;
}
body .template_form .radio-button-2 {
  border-color: var(--color-grading-2) !important;
  transition: all 0.3s;
}
body .template_form .radio-button-2:focus-within {
  box-shadow: var(--color-grading-opacity-2) 0 0 0 3px !important;
  transition: all 0.3s;
}
body .template_form .radio-button-2 .ant-badge-status-text {
  color: var(--color-grading-2);
  transition: all 0.3s;
}
body .template_form .radio-button-3 {
  border-color: var(--color-grading-3) !important;
  transition: all 0.3s;
}
body .template_form .radio-button-3:focus-within {
  box-shadow: var(--color-grading-opacity-3) 0 0 0 3px !important;
  transition: all 0.3s;
}
body .template_form .radio-button-3 .ant-badge-status-text {
  color: var(--color-grading-3);
  transition: all 0.3s;
}
body .template-card .ant-card-body {
  padding: 16px;
}
body .quality-report-list .ant-list-item:first-child {
  padding-top: 0;
}
body .quality-report-list .ant-list-item:last-child {
  padding-bottom: 0;
}
iframe {
  display: none;
}
.galleryUploader {
  position: relative;
  height: 300px;
  width: 100%;
}
.galleryUploader > .ant-upload {
  padding: 24px;
  margin: 0;
  height: inherit;
  width: inherit;
}
.ant-pro-table-list-toolbar-right {
  display: contents;
  gap: 16px;
}
.ant-pro-table-list-toolbar-setting-items {
  margin-left: 16px;
}
.ant-pro-table-list-toolbar-setting-items {
  gap: 12px;
}
.ant-page-header {
  padding: 14px 24px;
}
.ant-page-header .ant-page-header-heading {
  margin-top: 4px;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #1f803f;
}
.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-page-header-footer {
  margin-bottom: -16px;
}
.ant-btn-primary {
  color: #fff;
  border-color: #1f803f;
  background: #1f803f;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-layout-content {
  background-color: #f0f2f5;
}
.ant-menu-light.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 2px solid #1f803f;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0px;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item::before {
  width: 100%;
  height: 100%;
}
.ant-modal .ant-modal-content {
  border-radius: 4px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  padding: 0px;
}
.ant-modal .ant-modal-title {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 4px 4px 0 0;
}
.ant-modal .ant-modal-close {
  position: absolute;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  transition: color 0.3s;
}
.ant-modal .ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.index_previewModalImage__B\+otl {
  margin-top: 17px;
}
.ant-pro-card {
  border-radius: 4px;
}
.ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  border-color: #1f803f;
  box-shadow: 0 0 0 2px rgba(5, 42, 12, 0.3);
  outline: 0;
}
.ant-input-outlined:focus-within {
  border-color: #1f803f;
  box-shadow: 0 0 0 2px rgba(31, 128, 63, 0.2);
}
.ant-input {
  border-radius: 4px;
}
.ant-empty-normal,
.ant-empty-description {
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-popover-inner {
  background-color: #fff !important;
  background-clip: padding-box !important;
  border-radius: 4px !important;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
  padding: 0px !important;
}
.ant-popover-title {
  min-width: 177px !important;
  min-height: 32px !important;
  margin: 0 !important;
  padding: 5px 16px 4px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-weight: 500 !important;
  border-bottom: 1px solid #f0f0f0 !important;
}
.ant-modal .ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 4px 4px;
}
.ant-modal .ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-space {
  margin-left: -43px;
}
.ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
}
.ant-pagination .ant-pagination-prev .ant-pagination-item-link:hover {
  border-color: #388c51;
}
.ant-pagination .ant-pagination-item {
  min-width: 32px;
  font-family: "Manrope", sans-serif;
  text-align: center;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: 0;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}
.ant-pagination .ant-pagination-item:hover {
  border-color: #388c51;
}
.ant-pagination .ant-pagination-item a {
  color: #388c51;
}
.ant-pagination .ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s;
}
.ant-pagination .ant-pagination-next .ant-pagination-item-link:hover {
  border-color: #388c51;
}
.ant-table-cell {
  font-weight: 400 !important;
}
.ant-table-cell.ant-table-column-has-sorters .ant-table-column-title {
  font-weight: 600 !important;
}
.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected > .ant-table-cell {
  background: #e9f2ec;
  border-color: rgba(0, 0, 0, 0.03);
}
.ant-pro-table-alert-container {
  padding-inline-end: 24px;
  padding-inline-start: 60px;
}
.ant-pro-table-alert-info {
  color: rgba(0, 0, 0, 0.85);
}
.ant-card .ant-card-head {
  font-weight: 500;
}
.ant-upload-wrapper .ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #388c51;
  font-size: 48px;
}
.ant-select-single .ant-select-selector {
  border-radius: 4px;
}
.ant-input-affix-wrapper {
  border-radius: 4px;
}
.ant-picker {
  border-radius: 4px;
}
.ant-input-group .ant-input-group-addon {
  border-radius: 4px;
}
.ant-btn {
  border-radius: 4px;
}
.ant-pro-card {
  border-radius: 4px;
}
.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected > .ant-table-cell {
  background: #e9f2ec;
  border-color: rgba(0, 0, 0, 0.03);
}
.ant-pro-table-alert {
  background-color: #e9f2ec;
  border-radius: 4px;
  border: 1px solid #77a682;
}
.ant-card .ant-card-head {
  min-height: 69px;
}
.ant-card {
  border-radius: 4px;
}
.ant-upload-wrapper .ant-upload-drag {
  border-radius: 4px;
}
.ant-upload-wrapper .ant-upload-drag .ant-upload {
  padding: 11px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload-wrapper .ant-upload-drag p.ant-upload-hint {
  margin-bottom: 6px;
}
.ant-radio-inner:after {
  width: 18px !important;
  height: 18px !important;
  margin-block-start: -9px !important;
  margin-inline-start: -9px !important;
  background-color: #1f803f !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #1f803f !important;
  background-color: #FFFFFF !important;
}
.galleryModal .ant-modal-close-x {
  margin-top: 10px;
}
.ant-menu-submenu:hover .ant-menu-submenu-title {
  color: #1F803F;
}
.ant-upload-wrapper.ant-upload-picture-circle-wrapper {
  /* display: block; */
  display: inline-block;
  width: 100%;
}
.ant-btn-default.ant-btn-background-ghost {
  box-shadow: none;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: transparent;
}
.ant-select-dropdown {
  padding: 4px 0px;
  border-radius: 4px;
}
.ant-select-dropdown .ant-select-item {
  border-radius: 0px;
}
.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #e9f2ec;
}
.ant-menu-inline .ant-menu-item {
  width: calc(100% - 4px);
}
.ant-input::placeholder {
  color: #C9C9C9;
}
.ant-modal .ant-modal-body {
  padding: 24px;
}
.ant-input {
  border: 1px solid #d9d9d9;
}
.ant-select-multiple .ant-select-selector {
  border-radius: 4px;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  width: 100%;
  height: 100%;
}
.templates-box .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  width: 102px;
  height: 102px;
}
.qc-reports-image-box .ant-card-body .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  width: 176px;
  height: 300px;
}
.qc-reports-image-box .ant-card-body .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list-item-container {
  width: 176px;
  height: 300px;
}
.edit-qc-image .ant-card-body .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  width: 176px;
  height: 300px;
}
.edit-qc-image .ant-card-body .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list-item-container {
  width: 176px;
  height: 300px;
}
.lp-report-photo .ant-card-body .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  width: 176px;
  height: 300px;
}
.card .ant-card-body .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  width: 176px;
  height: 300px;
}
.lp-report-photo .ant-card-body .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list-item-container {
  width: 176px;
  height: 300px;
}
.Templates-info .ant-card-body .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 8px;
}
.Templates-info .ant-card-body .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item::before {
  height: 83px;
  width: 83px;
}
.ant-form-item-label {
  min-width: 114px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow > * {
  line-height: 1;
  padding-top: 7px;
}
.ant-modal-confirm-body-wrapper {
  padding: 33px;
}
.ant-modal-confirm .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.4;
}
.ant-modal-confirm .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.57142857;
}
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #388c51;
  border-color: #388c51;
  background: #fff;
}
.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
  background: #388c51;
}
.template-reports .ant-card-head-wrapper .ant-card-head-title {
  max-width: max-content !important;
}
.template-reports .ant-card-head-wrapper .ant-card-extra {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 167px);
}
.ant-btn-link {
  white-space: break-spaces;
  text-align: left;
}
