.loading {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
}

.absolute {
  background: rgba(255, 255, 255, 0.55);
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@font-family: 'Manrope', sans-serif;@primary-color: #1F803F;@border-radius-base: 4px;@primary-1: #E9F2EC;