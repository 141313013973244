.wrp,
.content {
  display: flex;
  justify-content: center;
}

.wrp {
  background-color: var(--color-page-background);
  max-width: 688px;
  width: 100%;
  padding: 50px 0 50px;
  flex-wrap: wrap;

  form {
    max-width: 360px;
    width: 90vw;
  }

  input {
    font-size: 14px;
  }
}

.content {
  width: 100%;
  margin-top: 48px;

  .box {
    margin-top: 10px;
  }
}

.link {
  height: 56px;

  .logo {
    width: 251px;
    height: 56px;
  }
}

@font-family: 'Manrope', sans-serif;@primary-color: #1F803F;@border-radius-base: 4px;@primary-1: #E9F2EC;