.wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;

  .message {
    color: var(--color-gray-6);
  }
}

@font-family: 'Manrope', sans-serif;@primary-color: #1F803F;@border-radius-base: 4px;@primary-1: #E9F2EC;