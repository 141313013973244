.wrapBackText {
  display: flex;
  align-items: center;

  .smallText {
    margin-left: 12px;
    font-size: 14px;
    color: var(--color-text-secondary);
    font-weight: 400;
    line-height: 22px;
  }
}

.radioButtonsWrap {
  display: flex;
  flex-direction: column;
}

.spaceItemRadio {
  > div {
    > label {
      align-items: center;
    }
  }
}

@font-family: 'Manrope', sans-serif;@primary-color: #1F803F;@border-radius-base: 4px;@primary-1: #E9F2EC;