.requiredElem {
  &:before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.setStatusBtn {
  display: grid !important;
  align-items: center;
  grid-template-columns: auto 1fr;
  cursor: not-allowed !important;
  text-transform: capitalize;

  > svg {
    margin-right: 8px;
  }

  &.in-progress {
    text-transform: none;
    border-color: var(--color-orange);
    background-color: var(--color-orange);
    cursor: pointer !important;

    &:hover,
    &:focus,
    &:focus-visible,
    &:active {
      border-color: var(--color-orange);
      background-color: var(--color-orange);
    }
  }

  &.ready {
    text-transform: capitalize;
    border-color: var(--color-blue);
    background-color: var(--color-blue);

    &:hover,
    &:focus,
    &:focus-visible,
    &:active {
      border-color: var(--color-blue);
      background-color: var(--color-blue);
    }
  }
}

.cardHead {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 15px;
  align-items: center;
}

@font-family: 'Manrope', sans-serif;@primary-color: #1F803F;@border-radius-base: 4px;@primary-1: #E9F2EC;